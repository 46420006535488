export default [
  {
    header: 'About Us',
    items: [
      {
        path: '/contact',
        label: 'Contact Us',
      },
      {
        path: '/contact',
        label: 'Customer Support',
      },
      {
        path: '/',
        label: 'About Us',
      },
    ],
  },
  {
    header: 'Our Information',
    items: [
      {
        path: '/privacy',
        label: 'Privacy Policy',
      },
      {
        path: '/terms',
        label: 'Terms & Conditions',
      },
      {
        path: '/',
        label: 'Site Map',
      },
    ],
  },
  {
    header: 'Socials',
    items: [
      {
        path: '/',
        label: 'Twitter',
      },
      {
        path: '/',
        label: 'Instagram ',
      },
      {
        path: '/',
        label: 'Facebook',
      },

    ],
  },

];
