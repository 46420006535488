export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'feature',
    label: 'Features',
  },
  {
    path: 'signup',
    label: 'Newsletter',
  },
  {
    path: 'faq',
    label: 'FAQ',
  },
];
